import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";

import { FaSquareReddit, FaSquareXTwitter } from "react-icons/fa6";
import { IoShieldCheckmark } from "react-icons/io5";
import { IoLogoYoutube } from "react-icons/io";

import Logo from "./Logo";

const Footer = () => {
	return (
		<footer className="left-0 right-0 w-full dark:bg-gray-900">
			<hr className="my-6 border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-8" />
			<div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
				<div className="md:flex md:justify-between">
					<div className="mb-6 md:mb-0">
						<Link to="/" className="flex items-center">
							<Logo />
							<span className="self-center whitespace-nowrap text-2xl font-semibold text-blue-400">
								Cool Press
							</span>
						</Link>
						<p className="pt-6">If you could see things from a bird's-eye view, wouldn't you learn something new?</p>
					</div>
					<div className="grid grid-cols-2 gap-8 sm:grid-cols-2 sm:gap-6">
						<div>
							<h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 dark:text-white">
								Resources
							</h2>
							<ul className="font-medium text-gray-500 dark:text-gray-400">
								<li className="mb-4">
									<Link
										to="/about"
										className="hover:underline"
									>
										About
									</Link>
								</li>
								<li className="mb-4">
									<Link
										to="/contact"
										className="hover:underline"
									>
										Contact Us
									</Link>
								</li>
							</ul>
						</div>

						<div>
							<h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 dark:text-white">
								Legal
							</h2>
							<ul className="font-medium text-gray-500 dark:text-gray-400">
								<li className="mb-4">
									<Link
										to="/privacy-policy"
										className="hover:underline"
									>
										Privacy Policy
									</Link>
								</li>
								<li className="mb-4">
									<Link
										to="/terms-and-conditions"
										className="hover:underline"
									>
										Terms &amp; Conditions
									</Link>
								</li>
								<li className="mb-4">
									<Link
										to="/copyright"
										className="hover:underline"
									>
										Copyright
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<hr className="my-6 border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-8" />
				<div className="sm:flex sm:items-center sm:justify-between">
					<span className="text-sm text-gray-500 dark:text-gray-400 sm:text-center">
						© 1997-2025{" "}
						<Link to="/" className="hover:underline">
							Cool Press
						</Link>
						. All Rights Reserved.
					</span>

					<div className="text-sm flex-row flex align-middle justify-center text-gray-500 dark:text-gray-400 sm:text-center">
					<IoShieldCheckmark className="mr-2" size={20} />
   <p>Secured by Big Boys</p>
</div>

					<div className="mt-4 flex sm:mt-0 sm:justify-center">
						
						<Link
							to={"https://www.instagram.com/coolpress_com/"}
							className="ms-5 text-gray-500 hover:text-gray-900 dark:hover:text-white"
						>
							<FaInstagram className="h-6 w-6" />
							<span className="sr-only">Instagram</span>
						</Link>
						<Link
							to={"https://x.com/C00lPress"}
							className="ms-5 text-gray-500 hover:text-gray-900 dark:hover:text-white"
						>
							<FaSquareXTwitter className="h-6 w-6" />
							<span className="sr-only">Twitter / X Account</span>
						</Link>

			{/* <Link
				to={"https://www.reddit.com/user/coolpress_com/"}
				className="ms-5 text-gray-500 hover:text-gray-900 dark:hover:text-white"
			>
				<FaSquareReddit className="h-6 w-6" />
				<span className="sr-only">Reddit</span>
			</Link> */}


						<Link
							to={"https://www.youtube.com/@CoolPress"}
							className="ms-5 text-gray-500 hover:text-gray-900 dark:hover:text-white"
						>
							<IoLogoYoutube className="h-6 w-6" />
							<span className="sr-only">YouTube</span>
						</Link>
						
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
